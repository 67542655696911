import React from 'react';
import './fm-trending-topics.scss';

import { FMButton } from '@own/fds-react';
import { FeatureLink } from '../../../models/experiencefragments/featurelinkslist';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props {
    popularLinks: FeatureLink[];
}
const FMTrendingTopics = (props: Props) => {
    const [fireEvents] = useAnalytics();

    return (
        <>
            {props.popularLinks?.length > 0 && (
                <div className="trending-topics-container">
                    {props.popularLinks.map((link: FeatureLink) => {
                        return (
                            <>
                                {link.title && (
                                    <FMButton
                                        type="secondary"
                                        label={link.title}
                                        ariaLabel={link.ariaLabel}
                                        href={link.url}
                                        key={link.title.toString()}
                                        onClick={() => {
                                            fireEvents(
                                                'fm-trending-topics-click-event',
                                                undefined,
                                                {
                                                    topicName: link.title as string,
                                                }
                                            );
                                        }}
                                        className="trending-topic-button"
                                        theme="dark"
                                    />
                                )}
                            </>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default FMTrendingTopics;
