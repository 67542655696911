import React, { useEffect, useState } from 'react';
import {
    FeatureLink,
    FeatureLinksListFragment,
} from '../../../models/experiencefragments/featurelinkslist';
import Link from '../link/link';
import './fm-links-list.scss';
import { URLUtil } from '../../utils/url-util/url-util';
import { FMAccordion } from '@own/fds-react';
import serverSideService from '../../../services/server-side-service/server-side-service';
import MarkupUtil from '../../utils/markup-util';
import { useAnalytics } from '../../../hooks/use-analytics';

const MOBILE_BREAKING_POINT = 992;

const FMLinksList = (props: FeatureLinksListFragment) => {
    const [isMobile, setIsMobile] = useState<boolean>(
        serverSideService.isServerSide()
            ? false
            : window?.innerWidth < MOBILE_BREAKING_POINT
    );
    const experienceContent = props;
    const categories = experienceContent?.categories
        ?.filter(
            category =>
                category.categoryTitle && category.categoryItems.length > 0
        )
        .slice(0, 4)
        .map(category => ({
            ...category,
            categoryItems: category.categoryItems.slice(0, 5),
        }));
    const [fireEvents] = useAnalytics();
    const handleAccordionClickEvent = (topicName: string) => {
        return async () =>
            fireEvents('support-topics-accordion', undefined, {
                topicName,
            });
    };

    const getHref = (ctaPath: string) =>
        URLUtil.isRelativeUrl(ctaPath) && ctaPath.startsWith('/')
            ? ctaPath.substring(1)
            : ctaPath;

    const handleResize = () => {
        setIsMobile(window?.innerWidth < MOBILE_BREAKING_POINT);
    };

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            window?.addEventListener('resize', handleResize);
        }
        return () => {
            if (serverSideService.isClientSide()) {
                window?.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    const links = (categoryItems: FeatureLink[]) => (
        <div className="fm-links-category__items">
            {categoryItems.map(item => (
                <Link
                    key={`${item.title}`}
                    className="fm-links-category__items__item"
                    href={getHref(item.url)}
                    aria-label={item.ariaLabel}
                    target={item.targetBlank ? '_blank' : ''}
                >
                    {MarkupUtil.isMarkup(`${item.title}`) ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: item.title as string,
                            }}
                        />
                    ) : (
                        item.title
                    )}
                </Link>
            ))}
        </div>
    );

    return (
        <>
            {experienceContent &&
                !experienceContent.hide &&
                categories &&
                (isMobile ? (
                    <div className="fm-links-list-mobile">
                        <FMAccordion
                            title={experienceContent.title}
                            panels={categories.map(category => ({
                                title: category.categoryTitle,
                                panel: links(category.categoryItems),
                                handleClick: handleAccordionClickEvent(
                                    category.categoryTitle
                                ),
                            }))}
                        />
                    </div>
                ) : (
                    <article className="fm-links-container">
                        <h2 className="fm-links-title">
                            {experienceContent.title}
                        </h2>
                        <section className="fm-links-categories">
                            {categories.map(category => {
                                return (
                                    <div
                                        key={category.categoryTitle}
                                        className="fm-links-category"
                                    >
                                        <h3 className="fm-links-category__title">
                                            {category.categoryTitle}
                                        </h3>
                                        {links(category.categoryItems)}
                                    </div>
                                );
                            })}
                        </section>
                    </article>
                ))}
        </>
    );
};

export default FMLinksList;
