import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import {
    DisclaimerContent,
    DisclaimersService,
} from '../../../services/disclaimers-service/disclaimers-service';
import {
    FMBannersProps,
    useFMBanners,
} from '../../../components/sections/fm-vehicle-portal/hook/use-fm-banners';
import {
    SignInSearchByVehicleSectionProps,
    useSignInSearchByVehicleContent,
} from './use-sign-in-search-by-vehicle-content';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { FMCarouselFragment } from '../../../models/experiencefragments/fm-carousel';

export function useProcessedFMHomeContent(
    disclaimers: DisclaimerContent[] = []
) {
    const category = 'home-page-version-3';

    const [fmCarouselHomePageContent] = useExperienceContent<
        FMCarouselFragment
    >(category, 'fm-carousel', 'fm_carousel');
    const fmBannerContent = useFMBanners(category, 'fm-overlay-banner', false);
    const signInSearchByVehicleContent = useSignInSearchByVehicleContent();
    let processedSignInSearchByVehicleContent: SignInSearchByVehicleSectionProps | null = null;
    if (signInSearchByVehicleContent) {
        const [
            processedContent,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            [
                'unauthenticatedTitle',
                'unauthenticatedDescription',
                'authenticatedTitle',
                'authenticatedDescription',
                'signInToDashboardTitle',
                'signInToDashboardDescription',
                'authenticatedStateTitle',
                'authenticatedStateDescription',
                'searchByVehicleTitle',
                'searchByVehicleDescription',
            ],
            'disclaimer',
            disclaimers,
            signInSearchByVehicleContent,
            (content, fields) => {
                return {
                    ...content,
                    unauthenticatedTitle: fields[0]?.node,
                    unauthenticatedDescription: fields[1]?.node,
                    authenticatedTitle: fields[2]?.node,
                    authenticatedDescription: fields[3]?.node,
                    signInToDashboardTitle: fields[4]?.node,
                    signInToDashboardDescription: fields[5]?.node,
                    authenticatedStateTitle: fields[6]?.node,
                    authenticatedStateDescription: fields[7]?.node,
                    searchByVehicleTitle: fields[8]?.node,
                    searchByVehicleDescription: fields[9]?.node,
                };
            }
        );

        disclaimers = disclaimers.concat(processedDisclaimers);
        processedSignInSearchByVehicleContent = processedContent as SignInSearchByVehicleSectionProps;
    }

    const processCardFields = (card: FMCardProps, fields: any) => {
        return {
            ...card,
            title: fields[0]?.plain,
            titleWithDisclaimer: fields[0]?.node,
            description: fields[1]?.plain,
            descriptionWithDisclaimer: fields[1]?.node,
        };
    };
    let processedFMCarousel: FMCarouselFragment | null = null;
    if (fmCarouselHomePageContent) {
        const [
            processedFields,
            processedDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['title', 'description'],
            'disclaimer',
            disclaimers,
            fmCarouselHomePageContent
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedCards,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                fmCarouselHomePageContent.fmCards,
                (card, fields) => processCardFields(card, fields)
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            processedFMCarousel = {
                ...fmCarouselHomePageContent,
                title: processedFields[0]?.node,
                description: processedFields[1]?.node,
                fmCards: processedCards,
            };
        }
    }

    let processedFMOverlayBanner: FMBannersProps | undefined;
    if (fmBannerContent) {
        const [
            processedFMBanner,
            processedDisclaimers,
        ] = DisclaimersService.processFMBanners(fmBannerContent, disclaimers);
        processedFMOverlayBanner = processedFMBanner;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    return {
        processedSignInSearchByVehicleContent,
        processedFMCarousel,
        processedFMOverlayBanner,
        processedDisclaimers: disclaimers,
    };
}
