import React from 'react';
import './fm-escape-hatch.scss';
import { HelpCardsProps } from '../../../views/page-not-found-view/hooks/use-help-cards-content';
import phone from '../../../assets/phone-icon-blue.svg';
import dealer from '../../../assets/dealer-pin-blue-updated.svg';
import repair from '../../../assets/repair-car-blue.svg';
import { FMButton } from '@own/fds-react';
interface Props {
    content: HelpCardsProps;
}
const FMEscapeHatch = (props: Props) => {
    return (
        <>
            {props.content?.escapeHatchTitle && (
                <div className="fm-escape-hatch-container">
                    <h3 className="fm-escape-hatch-heading">
                        {props.content.escapeHatchTitle}
                    </h3>
                    <div className="fm-escape-hatch-subheading">
                        {props.content.escapeHatchDescription}
                    </div>

                    <div className="fm-escape-hatch-buttons-container">
                        <FMButton
                            type={'secondary'}
                            label={
                                <div className="fm-escape-hatch-button">
                                    {props.content.contactUsHelpCardTitle}
                                    <img
                                        className="fm-escape-hatch-button-img"
                                        src={phone}
                                        aria-hidden={true}
                                    />
                                </div>
                            }
                            ariaLabel={
                                props.content.contactUsHelpCarAriaLabel ||
                                'contact us button'
                            }
                            href={props.content.contactUsHelpCardURL}
                            theme="light"
                        />
                        <FMButton
                            type={'secondary'}
                            label={
                                <div className="fm-escape-hatch-button">
                                    {props.content.myFordVehicleHelpCardTitle}

                                    <img
                                        className="fm-escape-hatch-button-img"
                                        src={repair}
                                        aria-hidden={true}
                                    />
                                </div>
                            }
                            ariaLabel={
                                props.content.myFordVehicleHelpCardAriaLabel ||
                                'my ford vehicle button'
                            }
                            href={props.content.myFordVehicleHelpCardURL}
                            theme="light"
                        />

                        <FMButton
                            type={'secondary'}
                            label={
                                <div className="fm-escape-hatch-button">
                                    {props.content.findVehicleHelpCardTitle}
                                    <img
                                        className="fm-escape-hatch-button-img"
                                        src={dealer}
                                        aria-hidden={true}
                                    />
                                </div>
                            }
                            href={props.content.findVehicleHelpCardURL}
                            ariaLabel={
                                props.content.findVehicleHelpCardAriaLabel ||
                                'find vehicle button'
                            }
                            theme="light"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default FMEscapeHatch;
