import React from 'react';
import { FMButtonProps } from '@own/fds-react/dist/types/fm-button.types';
import { FMBannersProps } from '../../sections/fm-vehicle-portal/hook/use-fm-banners';
import { FMButton } from '@own/fds-react';
import MarkupUtil from '../../utils/markup-util';

import './fm-overlay-banner.scss';

export interface CtaProps {
    onClickCta1?: () => void;
    onClickCta2?: () => void;
}
const FMOverlayBanner = (props: FMBannersProps & CtaProps) => {
    const {
        hide,
        title,
        description,
        imagePath,
        imageAltText,
        onClickCta1,
        onClickCta2,
    } = props;

    const cta1: FMButtonProps = {
        type: 'primary',
        target: props.openCTA1InNewTab ? '_blank' : '',
        label: props.cta1Label,
        ariaLabel: props.cta1AriaLabel,
        href: props.cta1Path,
        onClick: onClickCta1,
    };
    const cta2 = props.cta2Label
        ? ({
              type: 'secondary',
              target: props.openCTA2InNewTab ? '_blank' : '',
              label: props.cta2Label,
              ariaLabel: props.cta2AriaLabel,
              href: props.cta2Path,
              onClick: onClickCta2,
          } as FMButtonProps)
        : null;

    return (
        <>
            {!hide && (
                <article className="fm-overlay-banner">
                    {imagePath && (
                        <>
                            <img
                                className="fm-overlay-banner__img"
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${imagePath}`}
                                alt={imageAltText || ''}
                            />
                            <div className="fm-overlay-banner__gradient"></div>
                        </>
                    )}
                    <section className="fm-overlay-banner__content">
                        {title != null &&
                            (MarkupUtil.isMarkup(title.toString()) ? (
                                <h3
                                    className="fm-overlay-banner__content__title"
                                    dangerouslySetInnerHTML={{
                                        __html: title?.toString()?.trim(),
                                    }}
                                />
                            ) : (
                                <h3 className="fm-overlay-banner__content__title">
                                    {title}
                                </h3>
                            ))}

                        {description != null &&
                            (MarkupUtil.isMarkup(description.toString()) ? (
                                <div
                                    className="fm-overlay-banner__content__description"
                                    dangerouslySetInnerHTML={{
                                        __html: description?.toString()?.trim(),
                                    }}
                                />
                            ) : (
                                <div className="fm-overlay-banner__content__description">
                                    {description}
                                </div>
                            ))}
                        <div className="fm-overlay-banner__content__buttons">
                            {cta1 && cta1.label && <FMButton {...cta1} />}
                            {cta2 && cta2.label && <FMButton {...cta2} />}
                        </div>
                    </section>
                </article>
            )}
        </>
    );
};

export default FMOverlayBanner;
