import { useExperienceContent } from '../../../hooks/use-server-content';
import { FeatureLink } from '../../../models/experiencefragments/featurelinkslist';
import { ExperienceFragmentModel } from '../../../services/content-service/content-service.interface';

export interface FMMastheadContent extends ExperienceFragmentModel {
    hide: boolean;
    relatedCategoryLinksTitle: string;
    popularLinksTitle: string;
    popularLinksSubTitle: string;
    relatedCategoryLinksItems: FeatureLink[];
    popularLinksItems: FeatureLink[];
    mastheadImage: string;
}
export const useFMMastheadContent = (): FMMastheadContent => {
    const [content] = useExperienceContent<FMMastheadContent>(
        'home-page-version-3',
        'fm-masthead',
        'popular_links_sectio'
    );
    return content as FMMastheadContent;
};
