import React from 'react';
import { SignInSearchByVehicleSectionProps } from '../../../views/fm-home-page/hooks/use-sign-in-search-by-vehicle-content';
import { FMButton } from '@own/fds-react';
import './fm-signin-sbv.scss';
import {
    ShortcodeProviders,
    TileOnClickCtaInfo,
} from '../../../services/shortcode-service/shortcode-service';
import { useAnalytics } from '../../../hooks/use-analytics';

interface FMSignInSbvProps {
    signInSearchByVehicleContent: SignInSearchByVehicleSectionProps;
    authenticationState: boolean;
    setShowDraw: (show: boolean) => void;
}
const FMSignInSbv = (props: FMSignInSbvProps) => {
    const [fireEvents] = useAnalytics();
    const signInButtonLabel = props.authenticationState
        ? props.signInSearchByVehicleContent?.goToAccountDashboardCtaLabel
        : props.signInSearchByVehicleContent?.signInCtaLabel;
    const signInButtonAriaLabel = props.authenticationState
        ? props.signInSearchByVehicleContent?.goToAccountDashboardCtaAriaLabel
        : props.signInSearchByVehicleContent?.signInCtaAriaLabel;
    const title = props.authenticationState
        ? props.signInSearchByVehicleContent.authenticatedTitle
        : props.signInSearchByVehicleContent.unauthenticatedTitle;
    const description = props.authenticationState
        ? props.signInSearchByVehicleContent.authenticatedDescription
        : props.signInSearchByVehicleContent.unauthenticatedDescription;
    const handleClickEvent = async (
        eventName: string,
        shortCodeProvider = {} as ShortcodeProviders
    ) => {
        fireEvents(eventName, undefined, shortCodeProvider);
    };
    const handleSignInClick = async () => {
        const tileOnClickCtaInfo: TileOnClickCtaInfo = {
            ctaName: 'sign in',
        };
        (window as any).location =
            props.signInSearchByVehicleContent?.myAccountUrlPath;
        await handleClickEvent('fm-home-page-cta-event', {
            tileOnClickCtaInfo,
        });
    };
    const handleSearchClick = async () => {
        const tileOnClickCtaInfo: TileOnClickCtaInfo = {
            ctaName: 'search vehicle',
        };
        props.setShowDraw(true);
        await handleClickEvent('fm-home-page-cta-event', {
            tileOnClickCtaInfo,
        });
    };
    return (
        <div className={'fm-signin-sbv-wrapper'}>
            {title && description && (
                <div className={'fm-signin-sbv-content'}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            )}
            <div className="fm-signin-sbv-buttons">
                <FMButton
                    type={'primary'}
                    label={signInButtonLabel}
                    ariaLabel={signInButtonAriaLabel}
                    onClick={handleSignInClick}
                    theme={'dark'}
                />
                <FMButton
                    type={'secondary'}
                    label={
                        props.signInSearchByVehicleContent
                            ?.searchByVehicleCtaLabel
                    }
                    ariaLabel={
                        props.signInSearchByVehicleContent
                            ?.searchByVehicleCtaAriaLabel
                    }
                    theme={'dark'}
                    onClick={handleSearchClick}
                />
            </div>
        </div>
    );
};

export default FMSignInSbv;
