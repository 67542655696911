import React from 'react';
import './fm-masthead.scss';
import FMTrendingTopics from '../fm-trending-topics/fm-trending-topics';
import { FMMastheadContent } from '../../../views/fm-home-page/hooks/use-fm-masthead-content';
import PreImagineSearchBar from '../../sections/search-bar/pre-imagine-search-bar/pre-imagine-search-bar';
import { SearchContentProps } from '../../sections/search-bar/hooks/use-search-content';
import { Picture } from '../picture/picture';
interface Props {
    mastheadContent: FMMastheadContent;
    searchContent: SearchContentProps | null;
}
const FMMasthead = (props: Props) => {
    return (
        <>
            {props.mastheadContent?.popularLinksTitle && (
                <div className="fm-masthead">
                    <Picture
                        className="fm-masthead-image"
                        largeDesktopImagePath={encodeURI(
                            props.mastheadContent.mastheadImage
                        )}
                        largeDesktopImageWidth="1440"
                        largeDesktopImageHeight="456"
                        desktopImageWidth="1024"
                        desktopImageHeight="456"
                        tabletImageWidth="768"
                        tabletImageHeight="550"
                        mobileImageWidth="375"
                        mobileImageHeight="656"
                        desktopImagePath={encodeURI(
                            props.mastheadContent.mastheadImage
                        )}
                        tabletImagePath={encodeURI(
                            props.mastheadContent.mastheadImage
                        )}
                        mobileImagePath={encodeURI(
                            props.mastheadContent.mastheadImage
                        )}
                        imageAltText="masthead"
                    />
                    <div className="fm-masthead-overlay">
                        <div className="fm-masthead-content">
                            <h1 className="fm-masthead-search-title">
                                {props.mastheadContent.popularLinksTitle}
                            </h1>
                            {props.searchContent && (
                                <PreImagineSearchBar
                                    searchContent={props.searchContent}
                                    placeholder={
                                        props.mastheadContent
                                            .popularLinksSubTitle
                                    }
                                    hideSearchBarTitle={true}
                                />
                            )}
                            <FMTrendingTopics
                                popularLinks={
                                    props.mastheadContent?.popularLinksItems
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FMMasthead;
