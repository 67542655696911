import { useContent } from '../../../hooks/use-server-content';

export interface SignInSearchByVehicleSectionProps {
    unauthenticatedTitle: string;
    unauthenticatedDescription: string;
    authenticatedTitle: string;
    authenticatedDescription: string;
    signInCtaLabel: string;
    signInCtaAriaLabel: string;
    goToAccountDashboardCtaLabel: string;
    goToAccountDashboardCtaAriaLabel: string;
    myAccountUrlPath: string;
    searchByVehicleCtaLabel: string;
    searchByVehicleCtaAriaLabel: string;
    drawerTitle: string;
    drawerCloseButtonAriaLabel: string;
    signInToDashboardTitle: string;
    signInToDashboardDescription: string;
    signInToDashboardCtaLabel: string;
    signInToDashboardCtaAriaLabel: string;
    authenticatedStateTitle: string;
    authenticatedStateDescription: string;
    viewDashboardCta: string;
    viewDashboardCtaAriaLabel: string;
    searchByVehicleTitle: string;
    searchByVehicleDescription: string;
    yearModelTabLabel: string;
    vinTabLabel: string;
    yearDropDownLabel: string;
    modelDropDownLabel: string;
    vinInputLabel: string;
    searchCtaLabel: string;
    searchCtaAriaLabel: string;
    whereDoIFindMyVin: string;
    disclaimer: string;
}

export const useSignInSearchByVehicleContent = (): SignInSearchByVehicleSectionProps => {
    const [content, getValueByName] = useContent(
        'home-page-version-3',
        'sign-in-search-by-vehicle'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as SignInSearchByVehicleSectionProps;
};
