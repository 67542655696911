import { useContent } from '../../../hooks/use-server-content';
import { HelpCardsProps } from '../../page-not-found-view/hooks/use-help-cards-content';

export const useFMEscapeHatchContent = (): HelpCardsProps => {
    const [content, getValueByName] = useContent(
        'home-page-version-3',
        'escape-hatch'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as HelpCardsProps;
};
