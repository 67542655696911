import { useExperienceContent } from '../../../hooks/use-server-content';
import { ExperienceFragmentModel } from '../../../services/content-service/content-service.interface';

export interface FMTabbedFaqsContent extends ExperienceFragmentModel {
    hide: boolean;
    heading: string;
    tabTitle1: string;
    tab1Faqs: FMTabbedFaq[];
    tabTitle2: string;
    tab2Faqs: FMTabbedFaq[];
    tabTitle3: string;
    tab3Faqs: FMTabbedFaq[];
    tabTitle4: string;
    tab4Faqs: FMTabbedFaq[];
    tabTitle5: string;
    tab5Faqs: FMTabbedFaq[];
}
export interface FMTabbedFaq {
    question: string;
    text: string;
}

export const useFMTabbedFaqsContent = (): FMTabbedFaqsContent => {
    const [content] = useExperienceContent<FMTabbedFaqsContent>(
        'home-page-version-3',
        'fm-tabbed-faqs',
        'tabbed_faqs'
    );

    return content as FMTabbedFaqsContent;
};
