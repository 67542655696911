import React from 'react';
import './fm-tabbed-accordions.scss';
import { FMAccordion, FMTabs } from '@own/fds-react';
import { FMAccordionItemProps } from '@own/fds-react/dist/types/fm-accordion.types';
import {
    FMTabbedFaq,
    FMTabbedFaqsContent,
} from '../../../views/fm-home-page/hooks/use-tabbed-faqs-content';
import { useAnalytics } from '../../../hooks/use-analytics';

interface AccordionTab {
    title: string;
    faqs: FMTabbedFaq[];
}
interface Props {
    content: FMTabbedFaqsContent;
}

const FMTabbedAccordions = (props: Props) => {
    const formattedTabs = [] as AccordionTab[];
    const [fireEvents] = useAnalytics();

    if (props.content?.tabTitle1) {
        formattedTabs.push({
            title: props.content.tabTitle1,
            faqs: props.content.tab1Faqs,
        } as AccordionTab);
    }
    if (props.content?.tabTitle2) {
        formattedTabs.push({
            title: props.content.tabTitle2,
            faqs: props.content.tab2Faqs,
        } as AccordionTab);
    }
    if (props.content?.tabTitle3) {
        formattedTabs.push({
            title: props.content.tabTitle3,
            faqs: props.content.tab3Faqs,
        } as AccordionTab);
    }
    if (props.content?.tabTitle4) {
        formattedTabs.push({
            title: props.content.tabTitle4,
            faqs: props.content.tab4Faqs,
        } as AccordionTab);
    }
    if (props.content?.tabTitle5) {
        formattedTabs.push({
            title: props.content.tabTitle5,
            faqs: props.content.tab5Faqs,
        } as AccordionTab);
    }

    const handleFaqsTabClick = (tabTitle: string) => {
        fireEvents('fm-faqs-tabs-click-event', undefined, {
            featureName: tabTitle,
        });
    };

    const handleFaqsAccordionClick = (faqsAccordionQuestion: string) => {
        return () =>
            fireEvents('fm-faqs-accordion-click-event', undefined, {
                featureName: faqsAccordionQuestion,
            });
    };

    return (
        <>
            {props.content && formattedTabs.length > 0 && (
                <div className="fm-tabbed-accordions-container">
                    <h2 className="fm-tabbed-accordions-title ">
                        {props.content.heading}
                    </h2>
                    <div className="fm-tabbed-accordions">
                        <FMTabs
                            panels={formattedTabs.map(tab => {
                                return {
                                    title: tab.title,
                                    panel: (
                                        <FMAccordion
                                            panels={tab.faqs.map(
                                                (faq: FMTabbedFaq) => {
                                                    return {
                                                        title: faq.question,
                                                        panel: faq.text,
                                                        handleClick: handleFaqsAccordionClick(
                                                            faq.question
                                                        ),
                                                    } as FMAccordionItemProps;
                                                }
                                            )}
                                            theme="dark-on-light"
                                        />
                                    ),
                                    handleClick: () =>
                                        handleFaqsTabClick(tab.title),
                                };
                            })}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default FMTabbedAccordions;
