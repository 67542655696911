import React, { useCallback, useEffect, useState } from 'react';
import FMDrawer from '../../common/fm-drawer/fm-drawer';
import { SignInSearchByVehicleSectionProps } from '../../../views/fm-home-page/hooks/use-sign-in-search-by-vehicle-content';
import { FMButton, FMTabs, FMVehicleSelector } from '@own/fds-react';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import PreImagineVinSelector from '../pre-imagine-components/pre-imagine-vehicle-selector/pre-imagine-vin-selector/pre-imagine-vin-selector';
import PreImagineYmmSelector from '../pre-imagine-components/pre-imagine-vehicle-selector/pre-imagine-ymm-selector/pre-imagine-ymm-selector';
import './search-by-vehicle-drawer.scss';
import { useVehiclePageContent } from '../vehicle-portal/hooks/use-vehicle-page-content';
import {
    BUTTONS,
    FMVehicleSelectorContent,
} from '@own/fds-react/dist/types/vehicleSelector.types';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import ProfileService from '../../../services/profile-service/profile-service';
import { useHistory } from 'react-router-dom';
import { PageType } from '../view-template/page-type';
import { findByAlias } from '../../../routes';
import { goToMyAccount } from '../../../views/vehicle-dashboard-view/vehicle-dashboard-view';
import useUserProfile from '../../../hooks/use-user-profile';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { useAnalytics } from '../../../hooks/use-analytics';
import { SmashHomeClick } from '../../../services/shortcode-service/shortcode-service';

interface Props {
    showDraw: boolean;
    setShowDraw(showDraw: boolean): void;
    signInSearchByVehicleContent: SignInSearchByVehicleSectionProps;
    page: PageType;
    authenticationState: boolean;
}

export const SearchByVehicleDrawer = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const page = 'Home Page 3.0';
    const { signInSearchByVehicleContent, showDraw, setShowDraw } = props;
    const closeDraw = () => {
        setShowDraw(false);
    };
    const authService = new AuthenticationService();
    const profile = useUserProfile();
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const [vehicleAttributes, setVehicleAttributes] = useState<
        VehicleAttributes
    >();
    useEffect(() => {
        if (profile) {
            profileService.persistVehicleSelection(
                profile.profile.email,
                vehicleAttributes?.vin
            );
            if (profile.vehicles.length > 0) {
                setVehiclesData(
                    [...profile.vehicles]
                        .sort(
                            (vehicle1, vehicle2) =>
                                parseInt(vehicle2.modelYear, 10) -
                                parseInt(vehicle1.modelYear, 10)
                        )
                        .map(vehicle => {
                            return {
                                year: parseInt(vehicle.modelYear, 10),
                                make: vehicle.make,
                                model: vehicle.modelName?.trim(),
                                vin: vehicle.vin,
                                ownerState: vehicle.ownerState,
                                registrationNo: vehicle.licenseplate,
                                fuelType: vehicle.fuelType,
                                engineType: vehicle.engineType,
                                warrantyStartDate: vehicle.warrantyStartDate,
                            };
                        })
                );
            }
        }
    }, [profile]);
    const ymmContent = () => {
        return {
            title: signInSearchByVehicleContent?.yearModelTabLabel,
            panel: (
                <>
                    <div className={'home-page-ymm-container'}>
                        <PreImagineYmmSelector
                            ariaLabel={''}
                            options={[]}
                            yearDropdownLabel={
                                signInSearchByVehicleContent.yearDropDownLabel
                            }
                            modelDropdownLabel={
                                signInSearchByVehicleContent.modelDropDownLabel
                            }
                            submitLabel={
                                signInSearchByVehicleContent.searchCtaLabel
                            }
                            buttonType={'secondary'}
                            dataTestId={'home-page-ymm-selector'}
                            page={page}
                            submitButtonAriaLabel={
                                signInSearchByVehicleContent.searchCtaAriaLabel
                            }
                        />
                    </div>
                </>
            ),
        };
    };
    const vinContent = () => {
        return {
            title: signInSearchByVehicleContent?.vinTabLabel,
            panel: (
                <PreImagineVinSelector
                    page={page}
                    whereDoIFindVinCta={
                        signInSearchByVehicleContent?.whereDoIFindMyVin
                    }
                    buttonType={'secondary'}
                    submitLabel={signInSearchByVehicleContent?.searchCtaLabel}
                    submitButtonAriaLabel={
                        signInSearchByVehicleContent?.searchCtaAriaLabel
                    }
                />
            ),
        };
    };
    const vehiclePageContent = useVehiclePageContent();

    const fmVehicleSelectorContent: FMVehicleSelectorContent = {
        addVehicleCtaLabel:
            vehiclePageContent?.addVehicleCtaLabel || 'Add Vehicle',
        changeVehicleButtonLabel:
            vehiclePageContent?.changeVehicleButtonLabel || 'Change Vehicle',
        vinLabel: vehiclePageContent?.vinLabel || 'VIN:',
        switchVehicle: `${vehicleAttributes?.year} ${vehicleAttributes?.make} ${vehicleAttributes?.model}`,
    };
    const profileService = new ProfileService();
    const history = useHistory();

    const getVehicleFromUserPreferences = () => {
        if (vehiclesData.length <= 0) return;
        let vehicle: VehicleAttributes = vehiclesData[0];
        const selectedVin = profileService.getUserPreferences(
            profile?.profile.email
        )?.lastSelectedVin;
        if (selectedVin) {
            vehicle =
                vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                vehiclesData[0];
        }
        setVehicleAttributes(vehicle);
    };

    const goToDashboard = useCallback(() => {
        history.push(findByAlias('VehicleDashboardView'));
    }, [history]);
    const storeUserPreference = (vehicle: VehicleAttributes) => {
        profile &&
            profile.vehicles.length > 0 &&
            profileService.persistVehicleSelection(
                profile.profile.email,
                vehicle.vin
            );
    };
    useEffect(() => {
        getVehicleFromUserPreferences();
    }, [vehiclesData]);

    const VEHICLE_BUTTONS: BUTTONS = {
        ADD: 'Add Vehicle',
        CHANGE: 'Change Vehicle',
    };
    const handleAddVehicleClick = () => {
        const smashHomeClick: SmashHomeClick = {
            ctaName: 'add vehicle',
        };
        fireEvents('fm-search-vehicle-cta-event', undefined, {
            smashHomeClick,
        });
        goToMyAccount(vehiclePageContent);
    };
    const handleVehicleSelectAnalytics = () => {
        const smashHomeClick: SmashHomeClick = {
            ctaName: 'switch vehicle',
        };
        fireEvents('fm-search-vehicle-cta-event', undefined, {
            smashHomeClick,
        });
    };
    const selectVehicle = (label: string, index: number, value: string) => {
        if (value === VEHICLE_BUTTONS.ADD) {
            handleAddVehicleClick();
        } else {
            const selectedVehicle = vehiclesData[index];
            setVehicleAttributes(selectedVehicle);
            storeUserPreference(selectedVehicle);
            handleVehicleSelectAnalytics();
        }
    };
    const handleSignInClick = () => {
        const smashHomeClick: SmashHomeClick = {
            ctaName: 'sign in to vehicle dashboard',
        };
        fireEvents('fm-search-vehicle-cta-event', undefined, {
            smashHomeClick,
        });
        authService.login();
    };
    return (
        <FMDrawer
            className="search-by-vehicle-drawer"
            showDraw={showDraw}
            onClose={closeDraw}
        >
            <h2>{signInSearchByVehicleContent?.drawerTitle}</h2>
            {!props.authenticationState ? (
                <>
                    <h3>
                        {signInSearchByVehicleContent?.signInToDashboardTitle}
                    </h3>
                    <p className="sign-into-dashboard-description">
                        {
                            signInSearchByVehicleContent?.signInToDashboardDescription
                        }
                    </p>
                    <FMButton
                        type="primary"
                        data-testid="signInToDashboardCta"
                        label={
                            signInSearchByVehicleContent?.signInToDashboardCtaLabel
                        }
                        aria-label={
                            signInSearchByVehicleContent?.signInToDashboardCtaAriaLabel
                        }
                        onClick={handleSignInClick}
                    />
                </>
            ) : (
                <div className={'auth-dashboard-sign-in'}>
                    <h3>
                        {signInSearchByVehicleContent?.authenticatedStateTitle}
                    </h3>
                    <p className="sign-into-dashboard-description">
                        {
                            signInSearchByVehicleContent?.authenticatedStateDescription
                        }
                    </p>
                    {vehicleAttributes ? (
                        <>
                            <FMVehicleSelector
                                value={vehicleAttributes}
                                content={fmVehicleSelectorContent}
                                vehiclesData={vehiclesData}
                                handleMenuItemClick={selectVehicle}
                                hideVin={true}
                            />
                            <FMButton
                                type={'primary'}
                                label={
                                    signInSearchByVehicleContent?.viewDashboardCta
                                }
                                ariaLabel={
                                    signInSearchByVehicleContent?.viewDashboardCtaAriaLabel
                                }
                                onClick={goToDashboard}
                            />
                        </>
                    ) : (
                        <ActivityIndicator />
                    )}
                </div>
            )}
            <div className="line"></div>
            <h3>{signInSearchByVehicleContent?.searchByVehicleTitle}</h3>
            <p className="search-by-vehicle-description">
                {signInSearchByVehicleContent?.searchByVehicleDescription}
            </p>
            <FMTabs panels={[ymmContent(), vinContent()]} />
        </FMDrawer>
    );
};

export default SearchByVehicleDrawer;
